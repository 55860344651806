import React, { useEffect, useRef } from 'react';
import './index.css';
import { FaInstagram, FaFacebook, FaTicketAlt } from 'react-icons/fa';
import { Helmet } from 'react-helmet-async';

function App() {
  const cursorRef = useRef(null);
  const glowRef = useRef(null);

  useEffect(() => {
    const cursor = cursorRef.current;
    const glow = glowRef.current;
    let timeoutId;

    const moveCursor = (e) => {
      cursor.style.left = e.clientX + 'px';
      cursor.style.top = e.clientY + 'px';
      
      // Move glow effect
      glow.style.left = e.clientX + 'px';
      glow.style.top = e.clientY + 'px';
      glow.style.opacity = '1';

      // Reset the timeout
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        glow.style.opacity = '0';
      }, 150);
    };

    const handleMouseEnter = () => {
      cursor.style.width = '40px';
      cursor.style.height = '40px';
    };

    const handleMouseLeave = () => {
      cursor.style.width = '20px';
      cursor.style.height = '20px';
    };

    // Add touch ripple effect
    const createRipple = (event) => {
      const button = event.currentTarget;
      const ripple = document.createElement('span');
      const rect = button.getBoundingClientRect();
      
      const x = event.touches ? (event.touches[0].clientX - rect.left) : (event.clientX - rect.left);
      const y = event.touches ? (event.touches[0].clientY - rect.top) : (event.clientY - rect.top);
      
      ripple.style.left = `${x}px`;
      ripple.style.top = `${y}px`;
      ripple.className = 'ripple';
      
      button.appendChild(ripple);
      
      setTimeout(() => {
        ripple.remove();
      }, 600);
    };

    const createTrail = (e) => {
      if (window.innerWidth <= 768) return; // Don't create trails on mobile

      const trail = document.createElement('div');
      trail.className = 'trail';
      trail.style.left = e.clientX + 'px';
      trail.style.top = e.clientY + 'px';
      document.body.appendChild(trail);

      // Animate and remove
      requestAnimationFrame(() => {
        trail.style.opacity = '0.5';
        trail.style.transform = 'scale(1.5)';
        trail.style.transition = 'all 0.5s ease-out';

        setTimeout(() => {
          trail.style.opacity = '0';
          setTimeout(() => trail.remove(), 500);
        }, 50);
      });
    };

    const handleMouseMove = (e) => {
      moveCursor(e);
      if (Math.random() > 0.8) { // Create trails occasionally
        createTrail(e);
      }
    };

    const handleTouchStart = (e) => {
      const touch = e.touches[0];
      createRipple(e);
      
      // Add touch highlight
      const highlight = document.createElement('div');
      highlight.className = 'touch-highlight';
      highlight.style.left = `${touch.clientX}px`;
      highlight.style.top = `${touch.clientY}px`;
      document.body.appendChild(highlight);
      
      setTimeout(() => highlight.remove(), 1000);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('touchstart', handleTouchStart, { passive: true });

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchstart', handleTouchStart);
      clearTimeout(timeoutId);
    };
  }, []);

  const eventDetails = {
    instagram: "https://instagram.com/ikhorevents",
    facebook: "https://facebook.com/ikhorevents",
    ticketLink: "https://tinyurl.com/4zj8ky8j",
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "IKHOR Events",
    "description": "Curating Extraordinary Experiences in Charlotte, NC",
    "url": "https://ikhorevents.com",
    "logo": "https://ikhorevents.com/logo.png",
    "sameAs": [
      eventDetails.instagram,
      eventDetails.facebook
    ]
  };

  const handleButtonHover = (e) => {
    const button = e.currentTarget;
    const rect = button.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    
    const moveX = (x - centerX) / 10;
    const moveY = (y - centerY) / 10;
    
    button.style.setProperty('--moveX', `${moveX}px`);
    button.style.setProperty('--moveY', `${moveY}px`);
  };

  const handleButtonLeave = (e) => {
    const button = e.currentTarget;
    button.style.setProperty('--moveX', '0px');
    button.style.setProperty('--moveY', '0px');
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      
      <div className="cursor-effect" ref={cursorRef}></div>
      <div className="interactive-bg">
        <div className="glow" ref={glowRef}></div>
      </div>
      
      <main className="app">
        <header className="hero">
          <h1 className="title">IKHOR EVENTS</h1>
          <p className="neon-subtitle">Curating Extraordinary Experiences</p>
          <p className="location-text">Charlotte, NC</p>
          
          <nav className="social-links" aria-label="Social Links and Tickets">
            <a 
              href={eventDetails.instagram} 
              target="_blank" 
              rel="noopener noreferrer"
              className="social-button instagram"
              aria-label="Follow us on Instagram"
              onMouseMove={handleButtonHover}
              onMouseLeave={handleButtonLeave}
            >
              <FaInstagram size={20} /> Instagram
            </a>
            <a 
              href={eventDetails.facebook} 
              target="_blank" 
              rel="noopener noreferrer"
              className="social-button facebook"
              aria-label="Join us on Facebook"
              onMouseMove={handleButtonHover}
              onMouseLeave={handleButtonLeave}
            >
              <FaFacebook size={20} /> Facebook
            </a>
            <a 
              href={eventDetails.ticketLink} 
              target="_blank" 
              rel="noopener noreferrer"
              className="social-button ticket"
              aria-label="Get your tickets now"
              onMouseMove={handleButtonHover}
              onMouseLeave={handleButtonLeave}
            >
              <FaTicketAlt size={20} /> Get Tickets
            </a>
          </nav>
        </header>

        <section className="flyer-section" aria-label="Event Flyer">
          <img 
            src="/ikhorFlyer.jpg" 
            alt="Latest IKHOR Event in Charlotte, NC" 
            className="event-flyer"
            loading="eager"
          />
        </section>
      </main>
    </>
  );
}

export default App;
